<template>
    <v-radio
        color="gradient-checkbox-post-destination"
        :value="value"
        dense
        hide-details
        v-bind="$attrs"
        v-on="$listeners"
        :class="{'dark-icon': darkIcon}"
    >
        <template v-slot:label>
            <slot/>
        </template>
    </v-radio>
</template>

<script>
export default {
    name: "Radio",
    props: {
        value: {
            required: true
        },
        darkIcon: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import '@/sass/modules/_variables';

.gradient-checkbox-post-destination--text {
    background-image: url(~@/assets/images/icons/active-radio-button-big.png);
    background-size: 20px 20px;
    background-position: center;

    &::before {
        content: "";
    }
}
.dark-icon .mdi-radiobox-blank{
    color: #707070;
}
</style>
