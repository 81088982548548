<template>
    <div class="block tags">
        <expand-block
            :radio="radio"
            @change-radio="handleChangeRadio"
            @click="onClickRadio(radio)"
        >
            <template v-slot:header>
                <div v-if="hint" style="display: block">
                    <div class="title_block">{{ title }}</div>
                    <div class="small">{{ hint }}</div>
                </div>
                <div class="title_block" v-else>{{ title }}</div>
            </template>
            <div
                v-if="customContent === false"
                class="test-class"
                @scroll="scroll"
            >
                <div v-for="item in sliced" :key="item.title">
                    <div class="mt-2" v-if="displayTagTitle">
                        <b>{{ item.title }}</b>
                    </div>

                    <v-row
                        cols="12"
                        v-for="(value, i) in item.items"
                        :key="i"
                        align="center"
                        class="pa-0 ma-0 pt-2"
                    >
                        <v-col v-if="withAvatars" cols="2">
                            <profile-image
                                :src="value.image"
                                :username="value.name"
                            />
                        </v-col>
                        <v-col :cols="withAvatars ? 8 : 10" class="pa-0 ma-0">
                            <div class="normal ml-4">
                                {{ value.title || value.name }}
                            </div>
                        </v-col>
                        <v-col cols="2" class="pt-0 pb-0">
                            <v-checkbox
                                :value="value.id"
                                hide-details
                                v-model="checks"
                                color="#ffab40"
                                dense
                                class="mt-0 pt-0"
                                @change="$emit('change', checks)"
                            />
                        </v-col>
                    </v-row>
                </div>
            </div>
            <slot></slot>
        </expand-block>
    </div>
</template>

<script>
import ExpandBlock from "@/components/app/posting/ExpandBlock";
import ProfileImage from "@/components/app/notifications/ProfileImage";

export default {
    name: "CheckBoxes",
    model: {
        prop: "checked",
        event: "change",
    },
    components: {
        ExpandBlock,
        ProfileImage,
    },
    data: () => ({
        checks: [],
        sliceCount: 50,
    }),
    props: {
        title: {
            type: String,
            required: true,
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        data: {
            required: true,
        },
        radio: {
            type: Object,
            default: () => ({ has: false, id: 0 }),
        },
        customContent: {
            default: false,
            type: Boolean,
        },
        withAvatars: {
            default: false,
            type: Boolean,
        },
        clear: {
            default: false,
            type: Boolean,
        },
        checkedAll: {
            default: false,
            type: Boolean,
        },
        checkedItem: {
            type: Number,
        },
        value: {
            required: false,
        },
        maxCount: {
            default: 0,
            type: Number,
        },
        displayTagTitle: {
            default: true,
            type: Boolean,
        },
    },
    watch: {
        clear(value) {
            if (value) {
                this.checks = [];
            }
        },
        checkedAll(value) {
            if (value) {
                this.checks = this.data.map((obj) => obj.id);
            } else {
                this.checks = [];
            }
        },
        checks() {
            if (this.maxCount && this.maxCount < this.checks.length) {
                this.checks = this.checks.slice(0, this.maxCount);
                this.$emit("change", this.checks);
            }
        },
        value() {
            this.updateChecks(this.value);
        },
    },
    computed: {
        sliced() {
            // return this.data.slice(0, this.sliceCount)

            function groupByGroupFormatted(items) {
                const grouped = items.reduce((accumulator, item) => {
                    if (!accumulator[item.group]) {
                        accumulator[item.group] = {
                            title: item.group,
                            items: [],
                        };
                    }

                    accumulator[item.group].items.push(item);
                    return accumulator;
                }, {});

                return Object.values(grouped);
            }

            return groupByGroupFormatted(this.data);
        },
    },
    methods: {
        updateChecks(array) {
            this.checks = array;
        },
        scroll(e) {
            const step = 900;
            this.sliceCount = (Math.floor(e.target.scrollTop / step) + 1) * 50;
        },
        getId(id) {
            return id.action === "new" ? id.user_id : id;
        },
        handleChangeRadio(radio) {
            this.$emit("change-radio", radio);
        },
        onClickRadio(radio) {
            const value = radio.value ? radio.value : "";
            this.$emit("click", value);
        },
    },
    mounted() {
        if (this.value) {
            this.checks = this.value;
        }
        if (this.checkedItem) {
            this.checks.push(this.checkedItem);
        }
        this.data.map((item) => {
            if (
                typeof item.id.action !== "undefined" &&
                item.id.action === "new"
            ) {
                this.checks.push(item.id);
                this.$emit("change-item", this.checks);
            }
        });
    },
};
</script>

<style lang="scss">
@import "@/sass/modules/_variables";

.test-class {
    max-height: 50vh;
    overflow-y: auto;
}

.block {
    background-color: #1a1a1a;
    border-radius: 16px;
    outline: none;
    width: 100%;
}

.post-destination {
    border-radius: 16px;
    font-size: 15px;
    background-color: #1a1a1a;
    color: white;
    width: 100%;
    padding: 0 1em;
}

.title_block {
    font-size: 1.1em;
}

.small {
    font-size: 10px;
    margin-top: 1em;
    color: rgba(255, 255, 255, 0.4);
}

.normal {
    font-size: 0.8em;
}
</style>
