<template>
    <v-expansion-panel class="expanded-panels" @click="changePanel">
        <v-expansion-panel-header class="expand-block-header__before-post pl-3 pt-0 pb-0" expand-icon="empty-icon">
            <v-row align="center">
                <v-col cols="1" >
                    <v-icon>mdi-chevron-{{ toggle === false ? 'down':'up' }}</v-icon>
                </v-col>
                <v-col cols="7">
                    <slot name="header"></slot>
                </v-col>
                <v-spacer/>
                <v-col cols="2" v-if="radio.has === true">
                    <radio @click.native.stop="handleChangeRadio(radio)" :value="radio.value"></radio>
                </v-col>
                <v-col cols="3" v-if="radio.has !== true">
                    <slot name="rightHeader"></slot>
                </v-col>
            </v-row>


        </v-expansion-panel-header>
        <v-expansion-panel-content class="expand-block-body__before-post">
            <slot></slot>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import Radio from "@/components/app/form/Radio";
export default {
    name: "ExpandBlock",
    components: {
        Radio
    },
    data: ()=>({
       toggle: false
    }),
    props:{
        radio: {
            type: Object,
            default: ()=>({has: false, value: 0})
        },
        color: {
            type: String,
            default: '#1A1A1A'
        }
    },
    methods:{
        changePanel() {
            this.toggle = !this.toggle;
        },
        handleChangeRadio(radio) {
            this.$emit('change-radio', radio)
            this.$emit('click')
        },
    },
}
</script>

<style scoped>
.expand-block-header__before-post.v-expansion-panel-header--active {
    background-color: #1A1A1A;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #7070704E;
}

.expand-block-header__before-post:not(.v-expansion-panel-header--active) {
    background-color: #1A1A1A;
    border-radius: 16px;
    transition: 0.7s;
    min-height: 60px;
}

.expand-block-body__before-post {
    background-color: #1A1A1A;
    border-radius: 0 0 16px 16px;
}
</style>
