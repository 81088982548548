<template>
    <div v-if="src" class="profile-image"
         :style="`background-image:url(${src}); width:${this.size}px; height:${this.size}px;`">
    </div>
    <div
        v-else
        class="profile-image profile-image--default"
        :style="`font-size: ${fontSize}px; width:${size}px; height:${size}px;`"
    >
        {{ firstLetters }}
    </div>
</template>

<script>
export default {
    name: "ProfileImage",
    props: {
        src: {
            type: String,
        },
        username: {
            type: String,
        },
        size: {
            type: Number,
            default: 40,
        },
        fontSize: {
            type: Number,
            default: 12,
        },
    },
    computed: {
        firstLetters() {
            return this.username?.slice(0, 2)?.toUpperCase()
        }
    }
}
</script>

<style scoped lang="scss">
$image-size: 40px;
.profile-image {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;

    &--default {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black !important;
        color: #FE4811;
    }
}
</style>
